import { useState, useCallback, useRef, useEffect } from 'react';
import { PaymentItemType } from '../../../../functions/api/v1/payment/ton/transactions/type';

export interface PendingPayment {
    itemType: PaymentItemType;
    priceId: string;
    price: number;
    transactionId: string;
    tonDestinationWalletAddress: string;
    createdAt: number;
    currencyId: string;
    itemId?: string;
}

const STORAGE_KEY = 'pending_payments';

export const usePaymentRecovery = () => {
    // 初期化済みフラグ
    const initialized = useRef(false);

    const [pendingPayments, setPendingPayments] = useState<PendingPayment[]>([]);

    useEffect(() => {
        if (initialized.current) return;
        initialized.current = true;

        try {
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored) {
                const payments = JSON.parse(stored);
                setPendingPayments(payments);
            }
        } catch {
            localStorage.removeItem(STORAGE_KEY);
        }
    }, []);

    const [showRecoveryModal, setShowRecoveryModal] = useState(pendingPayments.length > 0);

    const addPendingPayment = useCallback((payment: PendingPayment) => {
        setPendingPayments(prev => {
            const newPayments = [...prev, payment];
            localStorage.setItem(STORAGE_KEY, JSON.stringify(newPayments));
            return newPayments;
        });
        setShowRecoveryModal(true);
    }, []);

    const removePendingPayment = useCallback((transactionId: string) => {
        if (!transactionId) return; // 空のIDは処理しない

        setPendingPayments(prev => {
            const newPayments = prev.filter(p => p.transactionId !== transactionId);
            if (newPayments.length === 0) {
                localStorage.removeItem(STORAGE_KEY);
            } else {
                localStorage.setItem(STORAGE_KEY, JSON.stringify(newPayments));
            }
            return newPayments;
        });

        // ストレージが本当に消えたことを確認
        const remainingData = localStorage.getItem(STORAGE_KEY);
        if (!remainingData) {
            setShowRecoveryModal(false);
        }
    }, []);

    const clearAllPendingPayments = useCallback(() => {
        localStorage.removeItem(STORAGE_KEY);
        // 強制的にストレージをクリア
        window.localStorage.removeItem(STORAGE_KEY);
        setPendingPayments([]);
        setShowRecoveryModal(false);
        // 確実に消えたことを確認
        if (localStorage.getItem(STORAGE_KEY)) {
            localStorage.clear();
        }
    }, []);

    return {
        pendingPayments,
        showRecoveryModal,
        setShowRecoveryModal,
        addPendingPayment,
        removePendingPayment,
        clearAllPendingPayments,
    };
};