import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import {
    hapticFeedbackImpactOccurred,
    enableClosingConfirmation,
    disableClosingConfirmation
} from '@telegram-apps/sdk-react';
import { paths } from '../../../config/paths';

import {
    clickerAtom,
    coinsAtom,
    boostAtom,
    masterDataAtom,
    levelsAtom,
    isInitLoadingAtom,
    useGlobalActions,
    localClickerStateAtom,
    tapBotStateAtom,
    tapBotEarningsModalFlagAtom,
    currentLeagueAtom,
    dailyCheckinStateAtom
} from "../../atoms";

import { FullscreenClicker } from './FullscreenClicker';
import { GameHeader } from '../../../components/GameHeader/GameHeader';

import { EnergyControls } from '../../../components/clicker/EnergyControl/EnergyControl';
import { CoinPopupProps, CoinPopup } from '../../../components/clicker/popup/CoinPopup';
import TapBotEarningsModal from '../../../components/clicker/modals/clicker/ClickerModal';
import DailyCheckinModal from '../../../components/clicker/modals/daily/DailyCheckinModal';
import useEarningsPopup from '../../../components/clicker/modals/clicker/useEarningsPopup';
import useDailyCheckin from '../../../components/clicker/modals/daily/useDailyCheckin';
import { usePaymentRecovery } from '../../../components/payment/ton/usePaymentRecovery';
import PaymentRecoveryModal from '../../../components/payment/ton/PaymentRecovery';
import { useTonPayment } from '../../../components/payment/ton/usetonpayment';

import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';

import fujiyamaImage from '../../../assets/fujiyamatap-icons/stage/fujiyama.webp'
import washiEffectImage from '../../../assets/fujiyamatap-icons/stage/washi-effect.webp'

import stage1Image from '../../../assets/fujiyamatap-icons/stage/stage1/stage1.webp'
import stage1BoostedImage from '../../../assets/fujiyamatap-icons/stage/stage1/stage1-boosted.webp'
import stage2Image from '../../../assets/fujiyamatap-icons/stage/stage2/stage2.webp'
import stage2BoostedImage from '../../../assets/fujiyamatap-icons/stage/stage2/stage2-boosted.webp'
import stage3Image from '../../../assets/fujiyamatap-icons/stage/stage3/stage3.webp'
import stage3BoostedImage from '../../../assets/fujiyamatap-icons/stage/stage3/stage3-boosted.webp'
import stage4Image from '../../../assets/fujiyamatap-icons/stage/stage4/stage4.webp'
import stage4BoostedImage from '../../../assets/fujiyamatap-icons/stage/stage4/stage4-boosted.webp'
import stage5Image from '../../../assets/fujiyamatap-icons/stage/stage5/stage5.webp'
import stage5BoostedImage from '../../../assets/fujiyamatap-icons/stage/stage5/stage5-boosted.webp'
import stage6Image from '../../../assets/fujiyamatap-icons/stage/stage6/stage6.webp'
import stage6BoostedImage from '../../../assets/fujiyamatap-icons/stage/stage6/stage6-boosted.webp'
import stage7Image from '../../../assets/fujiyamatap-icons/stage/stage7/stage7.webp'
import stage7BoostedImage from '../../../assets/fujiyamatap-icons/stage/stage7/stage7-boosted.webp'
import stage8Image from '../../../assets/fujiyamatap-icons/stage/stage8/stage8.webp'
import stage8BoostedImage from '../../../assets/fujiyamatap-icons/stage/stage8/stage8-boosted.webp'
import stage9Image from '../../../assets/fujiyamatap-icons/stage/stage9/stage9.webp'
import stage9BoostedImage from '../../../assets/fujiyamatap-icons/stage/stage9/stage9-boosted.webp'
import stage10Image from '../../../assets/fujiyamatap-icons/stage/stage10/stage10.webp'
import stage10BoostedImage from '../../../assets/fujiyamatap-icons/stage/stage10/stage10-boosted.webp'

interface LeagueStage {
    // Visual layers
    isDarkStage?: boolean;
    bgColor?: string;
    bgEffectLayer?: React.ReactNode;
    boostEffectLayer?: React.ReactNode;
    stageLayer: React.ReactNode;
    clickerSymbolLayer: React.ReactNode;
    clickerSymbolOverlayLayer?: React.ReactNode;
}

const LEAGUE_STAGES: LeagueStage[] = [
    {
        isDarkStage: false,
        bgColor: "#F7F8F8",
        bgEffectLayer: (
            <img src={washiEffectImage} alt="Washi Effect" className="opacity-50" />
        ),
        boostEffectLayer: (
            <img src={stage1BoostedImage} alt="Boost Effect" className="" />
        ),
        stageLayer: (
            <img src={stage1Image} alt="Background" className="" />
        ),
        clickerSymbolLayer: (
            <img src={fujiyamaImage} alt="Fuji Mountain" className="" />
        )
    },
    {
        isDarkStage: true,
        bgColor: "#000000",
        bgEffectLayer: (
            <img src={washiEffectImage} alt="Washi Effect" className="opacity-50" />
        ),
        boostEffectLayer: (
            <img src={stage2BoostedImage} alt="Boost Effect" className="scale-x-95 scale-y-95" />
        ),
        stageLayer: (
            <img src={stage2Image} alt="Background" className="scale-x-95 scale-y-95" />
        ),
        clickerSymbolLayer: (
            <img src={fujiyamaImage} alt="Fuji Mountain" className="" />
        )
    },
    {
        isDarkStage: false,
        bgColor: "#CCCCCC",
        bgEffectLayer: (
            <img src={washiEffectImage} alt="Washi Effect" className="opacity-50" />
        ),
        boostEffectLayer: (
            <img src={stage3BoostedImage} alt="Boost Effect" className="scale-x-[130%] scale-y-[130%] -translate-x-16 -translate-y-20" />
        ),
        stageLayer: (
            <img src={stage3Image} alt="Background" className="scale-x-[130%] scale-y-[130%] -translate-x-16 -translate-y-20" />
        ),
        clickerSymbolLayer: (
            <img src={fujiyamaImage} alt="Fuji Mountain" className="" />
        )
    },
    {
        isDarkStage: false,
        bgColor: "#C6EBF7",
        bgEffectLayer: (
            <img src={washiEffectImage} alt="Washi Effect" className="opacity-50" />
        ),
        boostEffectLayer: (
            <img src={stage4BoostedImage} alt="Boost Effect" className="" />
        ),
        stageLayer: (
            <img src={stage4Image} alt="Background" className="" />
        ),
        clickerSymbolLayer: (
            <img src={fujiyamaImage} alt="Fuji Mountain" className="" />
        )
    },
    {
        isDarkStage: false,
        bgColor: "#F9F0B2",
        bgEffectLayer: (
            <img src={washiEffectImage} alt="Washi Effect" className="opacity-50" />
        ),
        boostEffectLayer: (
            <img src={stage5BoostedImage} alt="Boost Effect" className="scale-x-[90%] scale-y-[90%]" />
        ),
        stageLayer: (
            <img src={stage5Image} alt="Background" className="scale-x-[90%] scale-y-[90%]" />
        ),
        clickerSymbolLayer: (
            <img src={fujiyamaImage} alt="Fuji Mountain" className="" />
        )
    },
    {
        isDarkStage: true,
        bgColor: "#1B1464",
        bgEffectLayer: (
            <img src={washiEffectImage} alt="Washi Effect" className="opacity-50" />
        ),
        boostEffectLayer: (
            <img src={stage6BoostedImage} alt="Boost Effect" className="scale-x-95 scale-y-95 -translate-y-6" />
        ),
        stageLayer: (
            <img src={stage6Image} alt="Background" className="scale-x-95 scale-y-95 -translate-y-6" />
        ),
        clickerSymbolLayer: (
            <img src={fujiyamaImage} alt="Fuji Mountain" className="" />
        )
    },
    {
        isDarkStage: false,
        bgColor: "#D7C48A",
        bgEffectLayer: (
            <img src={washiEffectImage} alt="Washi Effect" className="opacity-50" />
        ),
        boostEffectLayer: (
            <img src={stage7BoostedImage} alt="Boost Effect" className="scale-x-[110%] scale-y-[110%] -translate-y-24" />
        ),
        stageLayer: (
            <img src={stage7Image} alt="Background" className="scale-x-[110%] scale-y-[110%] -translate-y-24" />
        ),
        clickerSymbolLayer: (
            <img src={fujiyamaImage} alt="Fuji Mountain" className="" />
        )
    },
    {
        isDarkStage: true,
        bgColor: "#D7C48A",
        bgEffectLayer: (
            <img src={washiEffectImage} alt="Washi Effect" className="opacity-50" />
        ),
        boostEffectLayer: (
            <img src={stage8BoostedImage} alt="Boost Effect" className="scale-x-[110%] scale-y-[110%] -translate-y-24" />
        ),
        stageLayer: (
            <img src={stage8Image} alt="Background" className="scale-x-[110%] scale-y-[110%] -translate-y-24" />
        ),
        clickerSymbolLayer: (
            <img src={fujiyamaImage} alt="Fuji Mountain" className="" />
        )
    },
    {
        isDarkStage: false,
        bgColor: "#D7C48A",
        bgEffectLayer: (
            <img src={washiEffectImage} alt="Washi Effect" className="opacity-50" />
        ),
        boostEffectLayer: (
            <img src={stage9BoostedImage} alt="Boost Effect" className="scale-x-[110%] scale-y-[110%] -translate-y-24" />
        ),
        stageLayer: (
            <img src={stage9Image} alt="Background" className="scale-x-[110%] scale-y-[110%] -translate-y-24" />
        ),
        clickerSymbolLayer: (
            <img src={fujiyamaImage} alt="Fuji Mountain" className="" />
        )
    },
    {
        isDarkStage: false,
        bgColor: "#D7C48A",
        bgEffectLayer: (
            <img src={washiEffectImage} alt="Washi Effect" className="opacity-50" />
        ),
        boostEffectLayer: (
            <img src={stage10BoostedImage} alt="Boost Effect" className="scale-x-[110%] scale-y-[110%] -translate-y-24" />
        ),
        stageLayer: (
            <img src={stage10Image} alt="Background" className="scale-x-[110%] scale-y-[110%] -translate-y-24" />
        ),
        clickerSymbolLayer: (
            <img src={fujiyamaImage} alt="Fuji Mountain" className="" />
        )
    },
];

export const ClickerRoute = () => {
    const [popups, setPopups] = useState<CoinPopupProps[]>([] as CoinPopupProps[]);
    const buttonRef = useRef(null);
    const navigate = useNavigate();
    const {
        pendingPayments,
        showRecoveryModal,
        setShowRecoveryModal,
        clearAllPendingPayments
    } = usePaymentRecovery();
    const {
        cleanup: cleanupTonPayment
    } = useTonPayment();

    // グローバルステート
    const [clicker] = useAtom(clickerAtom);
    const [serverCoins] = useAtom(coinsAtom);
    const [boost] = useAtom(boostAtom);
    const [levels] = useAtom(levelsAtom);
    const [masterData] = useAtom(masterDataAtom);
    const [isInitLoading] = useAtom(isInitLoadingAtom);
    const [tapBotState] = useAtom(tapBotStateAtom);
    const [localState, setLocalState] = useAtom(localClickerStateAtom);
    const [, setIsModalEnabled] = useAtom(tapBotEarningsModalFlagAtom);
    const { sendClicks } = useGlobalActions();

    // ローカルステート
    const [unsyncedClicks, setUnsyncedClicks] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [currentLeague] = useAtom(currentLeagueAtom);
    const [dailyCheckinState] = useAtom(dailyCheckinStateAtom);

    // クリック処理用のref
    const sendTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const isSendingRef = useRef<boolean>(false);
    const lastSentClicksRef = useRef<number>(clicker.clicks);
    const hasTouch = useRef<boolean>(false);
    const interactionTimeRef = useRef<number>(0);

    // 計算値
    const isBoostActive = boost.effectiveUntil ? boost.effectiveUntil > Date.now() : false;
    const currentTapLevel = masterData.tapLevels.find(level => level.level === levels.tapLevel);
    const coinsPerClick = currentTapLevel?.earnableCoinPerClick ?? 0;

    const prevBoostActive = useRef(isBoostActive);

    const {
        showDailyCheckinModal,
        handleCloseModal: handleCloseDailyCheckinModal,
        handleSelectPackage,
        packages,
        isPerformingCheckin
    } = useDailyCheckin(isInitLoading);

    // 現在のステージの取得
    const currentStageIndex = currentLeague?.leagueId ? currentLeague.leagueId - 1 : 0;
    const currentStage = LEAGUE_STAGES[currentStageIndex];

    // カスタムフックを使用
    const { showEarningsModal, handleCloseModal } = useEarningsPopup({
        earnings: serverCoins.botEarnedCoins,
        isInitLoading
    });

    useEffect(() => {
        // コンポーネントのアンマウント時にフラグをリセット
        return () => {
            setIsModalEnabled(false);
        };
    }, []);

    useEffect(() => {
        lastSentClicksRef.current = clicker.clicks;
    }, [clicker.clicks]);

    // エナジーの自動回復処理
    useEffect(() => {
        const interval = setInterval(() => {
            if (localState.localEnergy < clicker.maxEnergy) {
                setLocalState(prev => (
                    {
                        ...prev,
                        localEnergy: Math.min(
                            clicker.maxEnergy,
                            prev.localEnergy + clicker.rechargeSpeed
                        )
                    }));
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [localState.localEnergy, clicker.maxEnergy, clicker.rechargeSpeed, setLocalState]);

    const getEventCoordinates = (e: React.MouseEvent | React.TouchEvent) => {
        const rectRef = buttonRef.current! as HTMLDivElement;
        const rect = rectRef.getBoundingClientRect();
        if (!rect) return null;

        let clientX: number;
        let clientY: number;

        if ('touches' in e) {
            // TouchEvent
            if (e.touches.length === 0) return null;
            clientX = e.touches[0].clientX;
            clientY = e.touches[0].clientY;
        } else {
            // MouseEvent
            clientX = e.clientX;
            clientY = e.clientY;
        }

        return {
            x: clientX,
            y: clientY - rect.height * 0.05
        };
    };

    // 未送信クリック数の監視と確認ダイアログの制御
    useEffect(() => {
        if (unsyncedClicks > 0) {
            enableClosingConfirmation();
        } else {
            disableClosingConfirmation();
        }

        // クリーンアップ時に確認ダイアログを無効化
        return () => {
            disableClosingConfirmation();
        };
    }, [unsyncedClicks]);

    const handleInteraction = useCallback((e: React.MouseEvent | React.TouchEvent) => {
        const effectiveCoinsPerClick = isBoostActive ? coinsPerClick * boost.multiplier : coinsPerClick;
        // タッチイベントとマウスイベントの重複を防ぐ
        if ('touches' in e) {
            hasTouch.current = true;
        }

        // touchstartの後のmousedownは無視
        if (!('touches' in e) && hasTouch.current) {
            return;
        }

        // 非ブースト時でエナジーが0の場合はクリックを無効化
        if (!isBoostActive && localState.localEnergy <= 0) {
            return;
        }

        const now = Date.now();
        // 10ms以内の連続イベントは無視
        interactionTimeRef.current = now;

        const energyCost = coinsPerClick;
        if (localState.localEnergy >= energyCost || isBoostActive) {
            setIsAnimating(true);
            setTimeout(() => setIsAnimating(false), 100);

            // クリックされた位置を取得
            const coords = getEventCoordinates(e);

            if (coords) {
                // ポップアップを追加
                const popupId = Date.now();
                setPopups(prev => [...prev, {
                    id: popupId,
                    x: coords.x,
                    y: coords.y,
                    amount: effectiveCoinsPerClick,
                    onComplete: () => {
                        setPopups(prev => prev.filter(p => p.id !== popupId));
                    },
                    tapbotLevel: tapBotState.level ? tapBotState.level : 0
                }]);
            }

            try {
                const earnedFromClick = effectiveCoinsPerClick;
                setLocalState(prev => {
                    // 今回のクリックで獲得したコイン数を加算
                    const newEarnedCoins = {
                        ...prev.localCoinCalculation.earnedCoins,
                        clickEarned: prev.localEarnedCoins + earnedFromClick,
                        totalEarned: prev.localCoinCalculation.earnedCoins.totalEarned + earnedFromClick
                    };

                    return {
                        ...prev,
                        lastClickTime: now,
                        localEnergy: !isBoostActive ? Math.max(0, prev.localEnergy - energyCost) : prev.localEnergy,
                        localEarnedCoins: prev.localEarnedCoins + earnedFromClick,
                        localCoinCalculation: {
                            ...prev.localCoinCalculation,
                            earnedCoins: newEarnedCoins,
                            totalCoins: newEarnedCoins.totalEarned - prev.localCoinCalculation.spentCoins.totalSpent
                        }
                    };
                });

                // クリック数の更新は一回だけ
                if (!isSendingRef.current) {
                    setUnsyncedClicks(prev => prev + 1);
                }

                if (hapticFeedbackImpactOccurred.isAvailable()) {
                    hapticFeedbackImpactOccurred('light');
                }

            } catch (error) {
                console.error('Click error:', error);
            }
        }
    }, [
        isBoostActive,
        coinsPerClick,
        setLocalState,
        setUnsyncedClicks,
        boost,
        localState,
        tapBotState
    ]);

    // コンポーネントのアンマウント時のクリーンアップ
    useEffect(() => {
        return () => {
            hasTouch.current = false;
            isSendingRef.current = false;
            if (sendTimeoutRef.current) {
                clearTimeout(sendTimeoutRef.current);
            }
            disableClosingConfirmation();
        };
    }, []);

    // クリック数のAPI送信処理を最適化
    // 2つないと何故か1回目しか送られない。原因は要調査。
    useEffect(() => {
        if (unsyncedClicks === 0) {
            return;
        }

        if (sendTimeoutRef.current) {
            clearTimeout(sendTimeoutRef.current);
        }

        sendTimeoutRef.current = setTimeout(async () => {
            if (unsyncedClicks > 0) {
                isSendingRef.current = true;
                const clicksToSend = unsyncedClicks;
                try {
                    sendClicks(clicksToSend);
                    lastSentClicksRef.current += clicksToSend;
                    setUnsyncedClicks(0);
                } catch (error) {
                    console.error('クリック送信エラー:', error);
                } finally {
                    isSendingRef.current = false;
                    sendTimeoutRef.current = null;
                }
            }
        }, 1000);

        return () => {
            if (sendTimeoutRef.current) {
                clearTimeout(sendTimeoutRef.current);
            }
        };
    }, [unsyncedClicks, sendClicks]);

    // boost終了検知用のuseEffect
    useEffect(() => {

        // boostの状態が変わった時に1回だけ実行
        if (prevBoostActive.current !== isBoostActive) {
            // boostが終了した時のみ実行
            if (!isBoostActive && unsyncedClicks > 0) {
                isSendingRef.current = true;
                const clicksToSend = unsyncedClicks;
                try {
                    sendClicks(clicksToSend);
                    lastSentClicksRef.current += clicksToSend;
                    setUnsyncedClicks(0);
                } catch (error) {
                    console.error('Error sending clicks on boost end:', error);
                } finally {
                    isSendingRef.current = false;
                }
            }
            // 状態を更新
            prevBoostActive.current = isBoostActive;
        }
    }, [isBoostActive, unsyncedClicks, sendClicks]);


    // 表示用の値を計算
    const displayValues = useMemo(() => {
        return {
            totalCoins: localState.localCoinCalculation.totalCoins,
        };
    }, [
        localState.localCoinCalculation.totalCoins,
    ]);

    // 未完了決済の処理ハンドラ
    const handleResumePayment = useCallback(async (transactionId: string) => {
        try {
            const payment = pendingPayments.find(p => p.transactionId === transactionId);
            if (!payment) return false;

            if (payment.itemType === 'daily_checkin') {
                // daily_checkinの場合は現在のページで処理
                console.log('Resuming daily check-in payment:', payment, 'itemId:', payment.itemId);
                await handleSelectPackage(
                    payment.currencyId === 'coin' ? 'basic-checkin' : 'premium-checkin', // [FIXME] DBから持ってくるべき。checkinのitemIdを直接入れている。
                    payment.priceId,
                    payment.currencyId,
                    payment.transactionId
                );
                setShowRecoveryModal(false);
                return true;
            } else {
                // その他のアイテムはitems画面に遷移
                navigate(paths.home.boosts.path, {
                    state: {
                        paymentData: payment
                    }
                });
                setShowRecoveryModal(false);
                return true;
            }
        } catch (error) {
            console.error('Payment recovery failed:', error);
            return false;
        }
    }, [pendingPayments, navigate, handleSelectPackage, setShowRecoveryModal]);

    // エラー時のクリーンアップ
    const handlePaymentCancel = useCallback(() => {
        clearAllPendingPayments();
        cleanupTonPayment();
    }, [clearAllPendingPayments, cleanupTonPayment]);

    if (isInitLoading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <div className="fixed h-screen overflow-hidden">
                <div className="fixed top-0 left-0 right-0 z-10">
                    <GameHeader
                        currentLeague={currentLeague}
                        isDarkStage={currentStage.isDarkStage}
                        totalCoins={displayValues.totalCoins}
                    />
                </div>
                <FullscreenClicker
                    ref={buttonRef}
                    onInteraction={handleInteraction}
                    isBoostActive={isBoostActive}
                    boostTimeRemaining={boost.effectiveUntil ? boost.effectiveUntil - Date.now() : null}
                    isAnimating={isAnimating}
                    disabled={!isBoostActive && localState.localEnergy <= 0}
                    // Visual layers
                    bgColor={currentStage.bgColor}
                    bgEffectLayer={currentStage.bgEffectLayer}
                    boostEffectLayer={currentStage.boostEffectLayer}
                    stageLayer={currentStage.stageLayer}
                    clickerSymbolLayer={currentStage.clickerSymbolLayer}
                    clickerSymbolOverlayLayer={currentStage.clickerSymbolOverlayLayer}
                />

                {/* Fixed Energy and Boost Controls - 4rem above bottom navigation */}
                <div className="fixed left-0 bottom-0 right-0 mb-36">
                    <EnergyControls
                        currentEnergy={localState.localEnergy}
                        maxEnergy={clicker.maxEnergy}
                        isBoostActive={isBoostActive}
                        isDarkStage={currentStage.isDarkStage}
                    />
                </div>

                {/* Coin Popups */}
                {popups.map(popup => (
                    <CoinPopup
                        key={popup.id}
                        {...popup}
                        tapbotLevel={tapBotState.level ? tapBotState.level : 0}
                    />
                ))}

                {/* Modals */}
                <PaymentRecoveryModal
                    isOpen={showRecoveryModal}
                    pendingPayments={pendingPayments}
                    onProcess={handleResumePayment}
                    onCancel={handlePaymentCancel}
                    dailyCheckinState={dailyCheckinState}
                    tapBotState={tapBotState}
                />
                <DailyCheckinModal
                    isOpen={showDailyCheckinModal}
                    onClose={handleCloseDailyCheckinModal}
                    onSelect={handleSelectPackage}
                    packages={packages}
                    isPerformingCheckin={isPerformingCheckin}
                />
                <TapBotEarningsModal
                    isOpen={showEarningsModal}
                    onClose={handleCloseModal}
                    earnings={serverCoins.botEarnedCoins}
                    botLevel={tapBotState.level ? tapBotState.level : 1}
                />
            </div >
        </>
    );
};

export default ClickerRoute;